<template>
  <div class="account_settings">
    <div class="content">
      <!-- 顶部面包屑 s -->
      <bread-crumb :nav_title="nav_title" path = "order"/>
      <!-- 顶部面包屑 end-->

      <div class="content_total">
        <!-- 侧边导航 start -->
        <nav-left :xuanzhong="3" />
        <!-- 侧边导航 end -->
        <div class="nav">
          <div class="nav_header">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <!--table1 全部订单 -->
              <el-tab-pane label="全部订单" name="first">
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->

                  <!-- 第一个表格 确认匹配-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
                <!-- 表格 end-->
              </el-tab-pane>
              <!--table2 匹配中 -->
              <el-tab-pane label="匹配中" name="second">
                <!-- 全部订单 start-->
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->
                  <!-- 第一个表格 确认匹配-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                  <!--  确认匹配-->
                </div>

                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
              </el-tab-pane>
              <!--table3 已匹配-->
              <el-tab-pane label="已匹配" name="third">
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->
                  <!-- 表格-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
              </el-tab-pane>
              <!--table4 已约课 -->
              <el-tab-pane label="已约课" name="fourth">
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格 end-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
              </el-tab-pane>
              <!--table5 已评价 -->
              <el-tab-pane label="已评价" name="fiveth">
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                  <!--评价已完成-->
                </div>
                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
              </el-tab-pane>
              <!--table6 完成 -->
              <el-tab-pane label="完成" name="sixth">
                <div class="no_evalution">
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 148px">手机号码</th>
                      <th style="width: 163px">合同编号</th>
                      <th style="width: 101px">匹配状态</th>
                      <th style="width: 155px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="5" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                        <td
                          colspan="5"
                          class="no_number yy_course"
                          v-if="item.status == 2"
                        >
                          <el-tag
                            type="success"
                            @click="orderAffirm(item.id, 3, item.course_number)"
                            >预约课程</el-tag
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                :src="item.hwds.hstp"
                              />
                              <div class="users_detail">
                                <span>{{item.teacher_name}}</span>
                                <p>{{item.service_name}}</p>
                              </div>
                              <div class="yuyue_times">
                                预约时间：<span
                                  >{{ item.createdDate }} - {{ item.end_time }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 148px">{{ item.mobile }}</td>
                        <td style="width: 163px">{{ item.contract_number }}</td>
                        <!-- 订单状态 start -->
                        <td
                          style="width: 101px"
                          v-if="item.is_match == 0 && item.is_cancel == 0"
                        >
                          <div class="free_time">
                            <span>匹配中</span>
                            <el-divider></el-divider>
                            <p class="dowmtimes" v-if="item.remaining_time !== '已超时'">
                              剩余时间
                            </p>
                            <div class="times" v-if="item.remaining_time !== '已超时'">
                              {{ item.remaining_time }}
                            </div>
                            <div class="times" v-else>已超时</div>
                          </div>
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 1"
                        >
                          已匹配
                        </td>

                        <td
                          class="complete_match"
                          style="width: 101px"
                          v-else-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          未匹配
                        </td>

                        <!-- 订单状态 end -->

                        <!-- 操作状态 start -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match == 0 && item.is_cancel == 1"
                        >
                          <div class="no_evalution_operation">
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link href="http://localhost/#/nomatch"
                                >重新预约</el-link
                              >
                            </div>
                            <div>
                              <el-link disabled
                                >已取消</el-link
                              >
                            </div>
                          </div>
                        </td>
                        <!-- 已取消end -->
                        <td
                          class="no_evalution_caozuo"
                          style="width: 151px"
                          v-if="item.is_match !== 0 || item.is_cancel !== 1"
                        >
                          <div class="no_evalution_operation">
                            <el-tag
                              @click="orderAffirm(item.id, 1)"
                              v-if="item.status == 1 &&  item.is_cancel !== 1"
                              >确认匹配</el-tag
                            >
                            <el-tag @click="queren(item.id, item.type)" v-if="item.status == 4"
                              >评价</el-tag
                            >
                            <div>
                              <el-link @click="$router.push(`/bokkings?orderid=${item.id}`)"
                                >查看详情</el-link
                              >
                            </div>
                            <div>
                              <el-link
                                @click="orderAffirm(item.id, 2)"
                                v-if="
                                  item.status == 1 ||
                                  item.status == 2 ||
                                  item.status == 3 ||
                                  item.status == 4
                                "
                                >取消订单</el-link
                              >
                            </div>
                          </div>
                        </td>

                        <!-- 操作状态 end -->
                      </tr>
                    </table>
                  </div>
                  <!--评价已完成-->
                </div>
                <!-- 分页器 -->
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="orderDataone.count"
                  :current-page="orderDataone.offset"
                  @current-change="orderclickOne"
                >
                </el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <matchPop
      v-if="matchDialogVisible"
      :orderId="orderId"
      :matchDialogVisible="matchDialogVisible"
      @matchCallbackFun="matchCallbackFun"
    />
    <cancelPop
      v-if="cancelDialogVisible"
      :orderId="orderId"
      :cancelDialogVisible="cancelDialogVisible"
      @cancelCallbackFun="cancelCallbackFun"
    />
    <yuyuePop
      v-if="yuyueDialogVisible"
      :orderId="orderId"
      :yuyueDialogVisible="yuyueDialogVisible"
      :yuyueList="yuyueList"
      @yuyueCallbackFun="yuyueCallbackFun"
    />
  </div>
</template>
<script>
import breadCrumb from "./components/breadcrumb";
import NavLeft from "./components/NavLeft";
import matchPop from "./components/pop/matchPop";
import cancelPop from "./components/pop/cancelPop";
import yuyuePop from "./components/pop/yuyuePop";
import { getOreder } from "@/api/order.js";
export default {
  data() {
    return {
      radio: 3,
      nav_title: [{ name: "订单中心" }, { name: "我的订单" }],
      activeName: "first",
      confirm: true,
      openOff: true,
      matchDialogVisible: false, //弹窗显示控制
      orderDataone: {
        offset: 1,
        data: {},
        count: 1,
      },
      orderDatatwo: {
        offset: 1,
        data: {},
        count: 1,
      },
      orderId: 0,
      cancelDialogVisible: false,
      yuyueDialogVisible: false,
      yuyueList: 2,
      type: 0,
    };
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
    matchPop,
    cancelPop,
    yuyuePop,
  },
  methods: {
    queren(id,type) {

      this.$router.push({ path: "/writes", query: { id: id, type: type }});
    },
    orderAffirm(orderId, type, course_number = 0) {
      console.log("orderAffirm", orderId);

      if (type == 1) {
        //匹配导师
        this.orderId = orderId;
        this.matchDialogVisible = true;
      } else if (type == 2) {
        //取消订单
        this.orderId = orderId;
        this.cancelDialogVisible = true;
      } else if (type == 3) {
        console.log(33);
        // 预约课程
        this.yuyueList = course_number;
        this.orderId = orderId;
        this.yuyueDialogVisible = true;
      }
    },
    matchCallbackFun() {
      //确定匹配关闭匹配弹框
      this.matchDialogVisible = false;
      this.initOrder();
    },
    cancelCallbackFun() {
      // 取消订单
      this.cancelDialogVisible = false;
      this.initOrder();
    },
    yuyueCallbackFun(type = 0) {
      // 取消预约
      this.yuyueDialogVisible = false;
      if (type == 1) {
        this.initOrder();
      }
    },
    initOrder() {
      // 初始化方法
      getOreder({
        type: this.type,
        limit: 10,
        offset: this.orderDataone.offset,
      }).then((res) => {
        this.orderDataone.data = res.data.list;
        this.orderDataone.count = res.data.count;
        console.log("orderDataone", this.orderDataone);
      });
    },
    orderclickOne(val) {
      //第一个分页器
      this.orderDataone.offset = val;
      console.log(val);
      this.initOrder();
    },
    handleClick(event) {
      // tabs切换
      console.log(event.index);
      if (event.index > 3) {
        this.type = event.index * 1 + 1;
      } else {
        this.type = event.index;
      }
      this.orderDataone.offset = 1;
      this.initOrder();
    },
  },
  mounted() {
    this.initOrder();
  },
};
</script>
<style lang="scss" scoped>
.account_settings {
  background: #f5f5f5;
  padding-bottom: 30px;

  .content {
    width: 1200px;
    margin: 0 auto;
    .content_total {
      display: flex;
      margin-top: 16px;
    }
    .nav {
      width: 990px;
      // height: 1780px;
      background: #ffffff;
      border-radius: 8px;
      margin-left: 10px;
      // 表头切换 s
      .nav_header {
        border-radius: 8px 8px 0px 0px;
        // display: none;
        /deep/ .el-tabs {
          .el-tabs__item {
            line-height: 0px;
            height: 35px;
            font-size: 18px;
            color: #666666;
          }
          .el-tabs__active-bar {
            width: 70px;
            height: 4px;
            background: #d99d66;
            border-radius: 2px;
          }
          .el-tabs__item.is-active {
            color: #d99d66;
            font-weight: bold;
          }
          .el-tabs__item:hover {
            color: #d99d66;
          }
          .el-tabs__nav-wrap::after {
            background-color: #fff !important;
          }
          .el-tabs__nav-scroll {
            background: #fcfcfc;
            padding: 25px 0 0 20px;
          }
        }
        // 表格标题
        /deep/ .el-table thead {
          font-size: 13px;
          font-weight: 500;
          color: #666666;
        }
        /deep/ .el-table--border {
          margin: 20px;
          .el-table td,
          .el-table th.is-leaf {
            font-size: 13px;
            font-weight: 500;
            color: #666666;
            line-height: 20px;
            // text-align: center;
          }
          .el-table__body td {
            text-align: center;
          }
        }
        // 表头边框去除
        /deep/ .el-table th,
        .el-table tr {
          background: #f5f5f5;
          border-radius: 4px 4px 0px 0px;
          text-align: center;
          border: none;
        }
      }
      // 表头切换 end
      // 操作start
      .no_evalution {
        margin: 20px;
        .no_evalution_title {
          width: 950px;
          height: 50px;
          background: #f5f5f5;
          border-radius: 4px;
          font-size: 13px;
          font-weight: 500;
          color: #666666;
          line-height: 50px;
          text-align: center;
        }
        .no_evalution_nums {
          margin-top: 20px;
          width: 950px;
          height: 133px;
          border-radius: 4px;
          border: 1px solid #ededed;
          text-align: center;
          .no_number {
            width: 950px;
            height: 30px;
            background: #fcfcfc;
            border-radius: 4px 4px 0px 0px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            padding-left: 20px;
            border: none;
            text-align: left;
          }
          // 预约课程
          .yy_course {
            font-size: 13px;
            font-weight: 400;
            color: #68c23a;
            /deep/ .el-tag {
              width: 69px;
              height: 22px;
              border-radius: 4px;
              border: 1px solid #68c23a;
              line-height: 22px;
              margin-right: 41px;
            }
          }
          td {
            border-top: 1px solid #ededed;
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            border-right: 1px solid #ededed;
            height: 102px;
          }
          .no_evalution_users {
            text-align: left;

            .users_info {
              display: flex;
              padding-left: 20px;
              flex-wrap: wrap;
              .users_detail {
                margin-left: 5px;
                p {
                  margin-top: 11px;
                }
              }
              .yuyue_times {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                margin-top: 14px;
                span {
                  color: #666666;
                }
              }
            }
          }
          .complete_match {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
          .no_evalution_caozuo {
            border-right: none;
          }
          .free_time {
            font-size: 13px;
            font-weight: 500;
            color: #333333;
            .dowmtimes {
              font-size: 12px;
              color: #999999;
            }
            /deep/.el-divider--horizontal {
              width: 83px;
              height: 1px;
              background: #ededed;
              margin: 13px 9px;
            }
            .times {
              margin-top: 5px;
            }
          }
          .timeouts > p {
            font-size: 13px;
            font-weight: 500;
            color: #f56c6c;
          }
          /deep/ .el-tag {
            height: 26px;
            border: 1px solid rgba(24, 144, 255, 0.5);
            display: block;
            margin: auto;
            font-size: 13px;
            padding: 0;
          }
          /deep/ .el-link.el-link--default {
            margin-top: 10px;
          }
          /deep/.el-divider__text,
          .el-link {
            font-size: 13px;
          }
          .no_evalution_operation p {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;
          }
        }
      }
      /deep/.el-divider--horizontal {
        width: 83px;
        height: 1px;
        background: #ededed;
        margin: 18px 9px;
      }
      // 操作end
    }
    // 分页器start
    /deep/ .el-pagination {
      color: #999999;
      text-align: center;
      padding-bottom: 40px;
      .el-pager li:not(.disabled).active {
        width: 44px;
        height: 30px;
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        border-radius: 4px 8px 4px 8px;
        font-size: 16px;
        color: #ffffff;
      }
      .btn-prev,
      .el-pager li {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
      }
      .btn-next {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        color: #cfcfcf;
      }
    }
    // 分页器end
  }
  .el-tag {
    width: 68px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid rgba(24, 144, 255, 0.5);
    text-align: center;
    background: #ffffff;
    cursor: pointer;
  }
  /deep/.el-tabs__header {
    margin: 0;
  }
}
</style>
